// src/api/hooks.js
import { useQuery, useMutation, useQueryClient,useInfiniteQuery } from 'react-query';
import axios from 'axios';
import { useAuth } from '@clerk/clerk-react';

const API_URL = process.env.REACT_APP_API_URL;

const useAuthenticatedApi = () => {
  const { getToken } = useAuth();

  const api = axios.create({
    baseURL: API_URL,
  });

  api.interceptors.request.use(async (config) => {
    const token = await getToken({ template: 'token' });
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  return api;
};

export const useGeneratedGroups = (limit = 6) => {
  const api = useAuthenticatedApi();

  return useInfiniteQuery(
    ['generatedGroups', limit],
    async ({ pageParam = 1 }) => {
      const { data } = await api.get(
        `/generated-images?page=${pageParam}&limit=${limit}&filter=org`
      );
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.page < lastPage.total_pages) {
          return lastPage.page + 1;
        } else {
          return undefined;
        }
      },
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};

export const useLikedUpscales = (activeTab, limit = 24) => {
   const api = useAuthenticatedApi();
 
   return useInfiniteQuery(
     ['likedUpscales', activeTab],
     async ({ pageParam = 1 }) => {
       const type = activeTab === 'liked' ? 'gallery' : 'upscales';
       const { data } = await api.get(`/images?type=${type}&page=${pageParam}&limit=${limit}`);
       return data;
     },
     {
       getNextPageParam: (lastPage) => {
         if (lastPage.page < lastPage.total_pages) {
           return lastPage.page + 1;
         } else {
           return undefined;
         }
       },
       keepPreviousData: true,
       staleTime: 5 * 60 * 1000, // 5 minutes
       cacheTime: 10 * 60 * 1000, // 10 minutes
       refetchOnWindowFocus: false,
       refetchOnMount: false,
     }
   );
 };

export const useUpscale = () => {
  const api = useAuthenticatedApi();
  const queryClient = useQueryClient();

  return useMutation(
    ({ generationId, imageId }) => api.post('/upscale', { generation_id: generationId, image_id: imageId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('generatedGroups');
        queryClient.invalidateQueries('likedUpscales');
      },
    }
  );
};

export const useSaveToGallery = () => {
  const api = useAuthenticatedApi();
  const queryClient = useQueryClient();

  return useMutation(
    ({ generationId, imageId }) => api.post('/save-to-gallery', { generation_id: generationId, image_id: imageId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('generatedGroups');
        queryClient.invalidateQueries('likedUpscales');
      },
    }
  );
};