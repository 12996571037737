export const productDos = [
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/product-do-1.png",
        description: "Well lit pictures"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/product-do-2.png",
        description: "Consistent product"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/product-do-3.png",
        description: "Main focus is the product"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/product-do-4.png",
        description: "Zoom in on details"
    }
];

export const productDonts = [
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/product-dont-1.png",
        description: "Avoid bad angles"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/product-dont-2.png",
        description: "Avoid heavy backgrounds"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/product-dont-3.png",
        description: "No blurry images"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/product-dont-4.png",
        description: "Train sides separately"
    }
];

export const personDos = [
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/person-do-1.png",
        description: "Well lit pictures"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/person-do-2.png",
        description: "Consistent expressions"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/person-do-3.png",
        description: "Always in focus"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/person-do-4.png",
        description: "Zoom in on that face"
    }
];

export const personDonts = [
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/person-dont-1.png",
        description: "Avoid bad angles"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/person-dont-2.png",
        description: "Avoid heavy backgrounds"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/person-dont-3.png",
        description: "No blurry images"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/person-dont-4.png",
        description: "Avoid odd expressions. No pouting! :P"
    }
];

export const petDos = [
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/pet-do-1.png",
        description: "Well lit pictures"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/pet-do-2.png",
        description: "Consistent expressions"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/pet-do-3.png",
        description: "Always in focus"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/pet-do-4.png",
        description: "Zoom in on that face"
    }
];

export const petDonts = [
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/pet-dont-1.png",
        description: "Avoid bad angles"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/pet-dont-2.png",
        description: "Avoid heavy backgrounds"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/pet-dont-3.png",
        description: "No blurry images"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/pet-dont-4.png",
        description: "Avoid odd expressions. *manifesting patience*"
    }
];