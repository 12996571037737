import React, { useState, useEffect } from 'react';
import { Heart, X, ArrowDown } from 'lucide-react';
import { toast } from 'react-toastify';
import { ReactComponent as Upscale } from '../../assets/icons/upscale.svg';
import { ReactComponent as UpscaleDone } from '../../assets/icons/upscale_done.svg';
import { useUser, useAuth } from '@clerk/clerk-react';
import IconButton from '../IconButton';

const ImageActions = ({
    generationId,
    image,
    onUpscale,
    onSaveToGallery,
    isSaved,
}) => {
    const isImageSaved = isSaved || image.saved_to_gallery;
    const isUpscaleRequested = image.upscale_status === 'REQUESTED';
    const isUpscaled = image.upscale_status === 'UPSCALED';

    let upscaleIcon,
        upscaleTitle,
        upscaleIconColor,
        isUpscaleDisabled,
        showUpscaleBorder;

    if (isUpscaleRequested) {
        upscaleIcon = UpscaleDone;
        upscaleTitle = 'Upscale in progress';
        upscaleIconColor = '#6bcc63';
        isUpscaleDisabled = true;
        showUpscaleBorder = false;
    } else if (isUpscaled) {
        upscaleIcon = UpscaleDone;
        upscaleTitle = 'View AI Upscale';
        upscaleIconColor = '#6bcc63';
        isUpscaleDisabled = true;
        showUpscaleBorder = false;
    } else {
        upscaleIcon = Upscale;
        upscaleTitle = 'AI Upscale';
        upscaleIconColor = 'white';
        isUpscaleDisabled = false;
        showUpscaleBorder = true;
    }

    return (
        <div className="flex space-x-2">
            <IconButton
                icon={upscaleIcon}
                onClick={() => onUpscale(image, generationId)}
                title={upscaleTitle}
                color={upscaleIconColor}
                fill="#ffffff00"
                showBorder={showUpscaleBorder}
            />
            <IconButton
                icon={Heart}
                onClick={() => onSaveToGallery(image, generationId)}
                title={isImageSaved ? 'Added to Gallery' : 'Save to Gallery'}
                disabled={isImageSaved}
                color={isImageSaved ? 'red' : 'white'}
                fill={isImageSaved ? 'red' : 'none'}
                showBorder={!isImageSaved}
            />
        </div>
    );
};

const SelectedImage = ({ selectedImage, onClickCancel }) => {
    const [imageType, setImageType] = useState('original');
    const [resolution, setResolution] = useState('');
    const { user } = useUser();
    const { getToken } = useAuth();

    console.log(selectedImage);

    const handleUpscale = async (image, generationId) => {
        if (!user) return;

        try {
            const token = await getToken({ template: 'token' });
            const response = await fetch(`${process.env.REACT_APP_API_URL}/upscale`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    generation_id: generationId,
                    image_id: image.image_id,
                }),
            });

            if (response.ok) {
                toast.success('Upscale requested, please check after 5 mins', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            } else {
                toast.error('Failed to request upscale. Please try again.', {
                    position: 'bottom-center',
                });
            }
        } catch (error) {
            console.error('Error requesting upscale:', error);
            toast.error('Failed to request upscale. Please try again.', {
                position: 'bottom-center',
            });
        }
    };

    const handleSaveToGallery = async (image, generationId) => {
        if (!user) return;

        try {
            const token = await getToken({ template: 'token' });
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/save-to-gallery`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        generation_id: generationId,
                        image_id: image.image_id,
                    }),
                }
            );

            if (response.ok) {
                toast.success('Image saved to gallery successfully!', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            } else {
                toast.error('Failed to save image to gallery', {
                    position: 'bottom-center',
                });
            }
        } catch (error) {
            console.error('Error saving image to gallery:', error);
            toast.error('Failed to save image to gallery', {
                position: 'bottom-center',
            });
        }
    };

    const handleDownload = imageUrl => {
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'generated-image.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(selectedImage.prompt)
            .then(() => {
                toast.success('Prompt Copied Successfully', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };

    const getImageResolution = (url) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(`${img.width}px x ${img.height}px`);
            img.src = url;
        });
    };

    useEffect(() => {
        const updateResolution = async () => {
            const res = await getImageResolution(
                imageType === 'original' ? selectedImage.image.image_url : selectedImage.image.upscale_url
            );
            setResolution(res);
        };
        updateResolution();
    }, [imageType, selectedImage]);


    return (
        <div className="inset-0 relative flex w-full h-full items-center bg-white z-50">
            <button
                className="absolute top-4 right-4 p-2 bg-opacity-50 rounded-full hover:bg-opacity-75 transition-all duration-200 ease-in-out"
                onClick={onClickCancel}
            >
                <X size={24} className="text-black" />
            </button>

            <div className="grid grid-cols-2 gap-4 p-4 w-full mx-10">
                <div className="w-full rounded-lg">
                    <img
                        src={imageType === 'original' ? selectedImage.image.image_url : selectedImage.image.upscale_url}
                        alt="Selected image"
                        className="max-w-full max-h-full object-contain rounded-lg"
                    />
                </div>
                <div className="w-full p-4 flex flex-col space-y-6">
                    <div className="text-wrap font-[500]">
                        {selectedImage.prompt}
                    </div>
                    <div className="flex   h-12 relative  items-center gap-2">
                        <div className="p-2 m-1 flex  text-sm break-words  bg-black bg-opacity-50 rounded-md hover:bg-opacity-75 cursor-pointer text-white  border-[1px] border-white "
                            onClick={handleCopy}>
                            Copy Prompt
                        </div>
                        <div>
                            <ImageActions
                                generationId={selectedImage?.generationId}
                                image={selectedImage?.image}
                                onUpscale={handleUpscale}
                                onSaveToGallery={handleSaveToGallery}
                                isSaved={false}
                            />
                        </div>
                        <div>
                            <IconButton
                                icon={ArrowDown}
                                onClick={() => handleDownload(
                                    imageType === 'original'
                                        ? selectedImage.image.image_url
                                        : selectedImage.image.upscale_url
                                )}
                                title="Download"
                                fill="#ffffff00"
                            />
                        </div>
                    </div>
                    <p className="text-sm mb-2">
                        <span className="font-bold">Resolution:</span> <span className="font-normal">{resolution}</span>
                    </p>
                    {selectedImage.image.upscale_url && (
                        <div>
                            <h3 className="text-lg font-semibold mb-2">
                                {imageType === 'original' ? 'View AI Upscaled image:' : 'View Original:'}
                            </h3>
                            <div className="grid grid-cols-3 gap-2 mb-4">
                                <div key={imageType === 'original' ? "upscale" : "original"} className="relative">
                                    <img
                                        src={imageType === 'original' ? selectedImage.image.upscale_url : selectedImage.image.image_url}
                                        alt={imageType === 'original' ? `Upscaled` : 'Original'}
                                        className="w-full h-auto rounded-md hover:border-2 hover:border-white transition-all duration-200"
                                        onClick={() => setImageType(imageType === 'original' ? 'upscale' : 'original')}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

};

export default SelectedImage;