// ModelImageUploadView.js
import React, { useState, useRef } from 'react';
import ImageCropper from '../components/ImageCropper';
import { ImagePlus} from 'lucide-react';
import axios from 'axios';
import { useAuth } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Loader from '../assets/icons/loader.svg'
import DoAndDontSection from '../components/DoAndDontSection';
import { 
  productDos, 
  productDonts, 
  personDos, 
  personDonts,
  petDos, 
  petDonts 
} from '../constants/doAndDontSectionConsts';

const SubHeader = ({ activeTab, setActiveTab }) => {
  const tabStyle = tab => `
    px-4 py-2 mx-2 rounded-md transition-colors duration-200
    ${
      activeTab === tab
      ? 'text-black font-semibold'
      : 'text-gray-500 hover:text-gray-700'
    }
  `;

  return (
    <div className="flex justify-between items-center py-2 px-6 border-b border-gray-200">
      <div className="flex">
        {/* Placeholder for logo or other left-aligned content */}
      </div>

      <div className="flex justify-center flex-grow p-2">Frame Your Model</div>
    </div>
  );
};

const ModelImageUploadView = () => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('your_models');
  const [showCropper, setShowCropper] = useState(false);
  const [croppedImages, setCroppedImages] = useState([]);
  const [productType, setProductType] = useState('product');
  const [modelIDR, setModelIDR] = useState(null);
  const [showProductDes, setShowProductDes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [modelDisLoad,setModelDisLoad]=useState(false);
  const [modelData, setModelData] = useState({
    brandName: 'Brand name',
    productName: 'Product name',
    person_pet_name:"name",
    description: 'Here is the description ',
  });
  const { getToken } = useAuth();

  const getDosAndDonts = () => {
    switch(productType) {
      case 'product':
        return { dos: productDos, donts: productDonts };
      case 'person':
        return { dos: personDos, donts: personDonts };
      case 'pet':
        return { dos: petDos, donts: petDonts };
      default:
        return { dos: productDos, donts: productDonts };
    }
  };

  const handleFileChange = e => {
    const files = Array.from(e.target.files).slice(0, 10); // Limit to 10 files
    const imageUrls = files.map(file => ({
      id: uuidv4(), // Assign a unique ID
      src: URL.createObjectURL(file),
    }));
    setUploadedImages(prevImages => [...prevImages, ...imageUrls]);
    setShowCropper(true);
    // setCroppedImages([]); // Reset cropped images when new files are uploaded
  };

  const handleProceed = () => {
    setShowCropper(true);
  };

  // const handleCropComplete = (id, croppedImageUrl) => {
  //   setCroppedImages(prevImages => {
  //     const newImages = [...prevImages];
  //     const index = newImages.findIndex(img => img.id === id);

  //     if (index !== -1) {
  //       newImages[index] = { id, src: croppedImageUrl };
  //     } else {
  //       newImages.push({ id, src: croppedImageUrl });
  //     }

  //     return newImages;
  //   });
  // };
  const handleCropComplete = (id, croppedImageUrl) => {
    setCroppedImages(prevImages => {
      const index = prevImages.findIndex(img => img.id === id);

      if (index !== -1) {
        // Update the existing image
        const newImages = [...prevImages];
        newImages[index] = { id, src: croppedImageUrl };
        return newImages;
      } else {
        // Add the new cropped image
        return [...prevImages, { id, src: croppedImageUrl }];
      }
    });
  };

  const compressImage = (file, maxWidth, maxHeight, quality) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = event => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            blob => {
              resolve(blob);
            },
            'image/jpeg',
            quality
          );
        };
      };
    });
  };

  const uploadImage = async (image, modelId, token, index) => {
    try {
      const compressedBlob = await compressImage(image, 4096, 4096, 0.95);
      const base64 = await convertToBase64(compressedBlob);

      const body = {
        blob: base64.split(',')[1],
        file_extension: '.jpg',
      };

      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/upload-training-image?model_id=${modelId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      return uploadResponse.data.image_url;
    } catch (error) {
      console.error(`Error uploading image ${index}:`, error);
      throw error;
    }
  };

  const handleFinishCropping = async () => {
    try {
      const token = await getToken({ template: 'token' });
      setLoading(true);
      // Step 1: Create the model
      const createModelResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/create-new-model`,
        { type: productType },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const modelId = createModelResponse.data.model_id;
      setModelIDR(modelId);

      // Step 2: Compress, upload each image and collect URLs
      setUploadLoader(true);
      const uploadPromises = croppedImages.map((image, index) =>
        uploadImage(image.src, modelId, token, index)
      );

      const uploadedImageUrls = await Promise.all(uploadPromises);
      setUploadLoader(false);
      setShowProductDes(true);
      setLoading(false);
      if (uploadedImageUrls.length === croppedImages.length && productType==='product') {
        setShowProductDes(true);
        setLoading(true);
        setModelDisLoad(true);
        try {
          const descriptionResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/create-model-description?model_id=${modelId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setModelData({
            brandName: descriptionResponse.data.brand,
            productName: descriptionResponse.data.product_name,
            description: descriptionResponse.data.description,
          });
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error('Error fetching model description:', error);
        }
      }
      // Update state with uploaded image URLs
      setUploadedImages(uploadedImageUrls);
    } catch (error) {
      setLoading(false);
      setUploadLoader(false);
      console.error('Error in handleFinishCropping:', error);
    }
  };

  const submitModalDescription = async () => {
    try {
      const token = await getToken({ template: 'token' });
      const formData = new FormData();
      formData.append('model_id', modelIDR); // Ensure modelId is defined and holds the correct value
      if(productType!=='product'){
        formData.append('person_pet_name', modelData.person_pet_name);
      }else{
        formData.append('brand_name', modelData.brandName);
        formData.append('product_name', modelData.productName);
        formData.append('description', modelData.description);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/submit-model-description`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // Do not set 'Content-Type'; Axios will handle it automatically
          },
        }
      );

      if (response) {
        navigate(`/model-image-captions/${modelIDR}`);
        // console.log('Model description submitted successfully:', response.data);
        // Perform any additional actions here (e.g., update state, navigate)
      }
    } catch (error) {
      console.error('Error submitting model description:', error);
      // Handle errors (e.g., show an error message to the user)
    }
  };
  const convertToBase64 = blob => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        resolve(base64String);
      };
      reader.onerror = error => reject(error);
      reader.readAsDataURL(blob);
    });
  };

  //   const handleFinishCropping = async () => {
  //     console.log(croppedImages, 'cropedImages');

  //     const formData = new FormData();
  //     croppedImages.forEach((blob, index) => {
  //       formData.append(
  //         `croppedImage_${index}`,
  //         blob,
  //         `cropped_image_${index}.png`
  //       );
  //     });

  //     // Send the cropped images to the backend
  //     await fetch('/api/upload-cropped-images', {
  //       method: 'POST',
  //       body: formData,
  //     });

  //     // Update uploaded images state
  //     setUploadedImages(prevImages => [
  //       // ...prevImages,
  //       ...croppedImages.map(blob => URL.createObjectURL(blob)), // Create URLs for the uploaded images
  //     ]);
  //     setShowCropper(false);
  //     setCroppedImages([]);
  //   };
  // Add the delete function to your parent component
  const handleDeleteImage = id => {
    setUploadedImages(prevImages =>
      prevImages.filter(image => image.id !== id)
    );
    setCroppedImages(prevCroppedImages =>
      prevCroppedImages.filter(image => image.id !== id)
    );
  };
  if (loading) {
    return (
      <div className="w-full  text-xl font-bold flex flex-col h-full justify-center items-center">
        {uploadLoader && <div>Uploading Images...</div>}
        {modelDisLoad && <div>Generating Model Description ...</div>}
        <img className="h-20" src={Loader} alt="loader"/>
      </div>
    );
  }

  return (
    <div className="w-full h-full grow flex flex-col overflow-hidden  ">
      <div className="w-full   mx-auto grow  flex flex-col  overflow-y-scroll ">
        <SubHeader activeTab={activeTab} setActiveTab={setActiveTab} />
        {!showProductDes ? (
          !showCropper ? (
            <div className=" flex flex-col flex-1">
              {/* Do's and Don'ts Section */}
              {/* ... (existing Do's and Don'ts content) */}

              <div className="flex w-full justify-center font-[500] my-4 ">

                <div className="grid grid-cols-3 w-1/3 font-[400] bg-[#F0F0F0] mt-2  rounded-md text-center">
                  <div
                    className={` cursor-pointer py-3 px-2 rounded-md ease-in-out  transition-colors duration-300 ${
                      productType === 'product'
                        ? 'bg-black text-white'
                        : 'bg-white-0 text-gray-400'
                      }`}
                    onClick={() => setProductType('product')}
                  >
                    Object
                  </div>
                  <div
                    className={` cursor-pointer py-3 px-2 rounded-md ease-in-out transition-colors duration-300 ${
                      productType === 'person'
                        ? 'bg-black text-white'
                        : 'bg-white-0 text-gray-400'
                      }`}
                    onClick={() => {
                      setProductType('person');
                    }}
                  >
                    Person
                  </div>
                  <div
                    className={`  cursor-pointer py-3 px-2 rounded-md ease-in-out transition-colors duration-300 ${
                      productType === 'pet'
                        ? 'bg-black text-white'
                        : 'bg-white-0 text-gray-400'
                      }`}
                    onClick={() => setProductType('pet')}
                  >
                    Pet
                  </div>
                </div>
              </div>
              <DoAndDontSection {...getDosAndDonts()} />
              {/* Image Upload Section */}
              <div className="  mx-10 border-gray-300 p-4 flex flex-col items-center">
                <label
                  htmlFor="file-upload"
                  className=" bg-black w-1/2 text-white py-4 px-10 rounded-lg cursor-pointer"
                >
                  <div className="flex w-full h-full gap-2 ">
                    <div className="h-full aspect-1 ">
                      <ImagePlus className="w-full h-full " />
                    </div>
                    <div>
                      <div className="flex w-full text-xl font-bold  ">
                        Upload 5-10 pictures of your model
                      </div>
                      <div className="flex w-full text-xs tracking-tight">
                        PNG or JPEG, Minimum resolution of 512x512, Max 10MB
                        size per image
                      </div>
                    </div>
                  </div>
                </label>
                <input
                  id="file-upload"
                  type="file"
                  ref={fileInputRef}
                  multiple
                  accept="image/png, image/jpeg"
                  className="hidden"
                  onChange={handleFileChange}
                ></input>
              </div>

              {/* Uploaded images display */}
              {/* <div className="grid grid-cols-4 h-full grow  gap-4 mt-8 mx-10">
                {uploadedImages.length === 0
                  ? Array(4)
                      .fill(0)
                      .map((_, index) => (
                        <div
                          key={index}
                          className="h-full w-full bg-gray-0 rounded-md"
                        ></div>
                      ))
                  : uploadedImages.map((image, index) => (
                      <div
                        key={index}
                        className="h-36 w-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-400"
                      >
                        <img
                          src={image.src}
                          alt={`Uploaded ${index}`}
                          className="h-full w-full object-contain"
                        />
                      </div>
                    ))}
              </div> */}

              {/* Proceed button */}
              {/* {uploadedImages.length > 0 && (
                <div className="flex justify-center mt-8">
                  <button
                    onClick={handleProceed}
                    className="bg-black text-white py-2 px-6 rounded"
                  >
                    Proceed
                  </button>
                </div>
              )} */}
            </div>
          ) : (
            <div className=" w-full h-full flex grow   flex-col">
              <div className="py-8 mx-10 grid grid-cols-4 gap-8 ">
                {/* Render an ImageCropper for each uploaded image */}
                {uploadedImages.map(image => (
                  <div
                    key={image.id}
                    className="bg-gray-100 rounded-md col-span-1"
                  >
                    <ImageCropper
                      src={image.src}
                      onCropComplete={croppedImageUrl =>
                        handleCropComplete(image.id, croppedImageUrl)
                      }
                      onDelete={() => handleDeleteImage(image.id)}
                    />
                  </div>
                ))}

                {/* Finish Cropping button */}
              </div>
            </div>
          )
        ) : (
          <div className="flex flex-col grow ">
            <div className="flex  grow h-full  items-center flex-col py-8">
              <div className="relative w-32 h-32 ">
                {uploadedImages.slice(0, 3).map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Uploaded ${index + 1}`}
                    className={`absolute w-[88px] object-cover rounded border-[1px] border-white
        ${index === 0 ? 'z-30 h-[139px]' : index === 1 ? 'z-20 h-[118px] transform origin-bottom-left' : 'z-10 h-[118px] transform origin-bottom-right'}
        bottom-0 left-1/2 transform -translate-x-1/2
        ${index === 1 ? '-rotate-[20deg] ' : ''}
        ${index === 2 ? 'rotate-[20deg] ' : ''}
      `}
                  />
                ))}
              </div>
              <div className="text-green-600 font-bold py-8">
                ✅ {uploadedImages.length} photos uploaded and analysed!
              </div>
                {/* for person and pet  */}
                {productType!=='product' ?
              <div className="flex w-full justify-center">
                <div className="mb-4  ">
                <label className="text-md font-bold text-gray-700  ">
                  Model Name
                </label>
                <input
                  value={modelData.person_pet_name}
                  onChange={e => {
                    setModelData({ ...modelData, person_pet_name: e.target.value });
                  }}
                  className="w-full font-bold p-4 mt-4 border-[1px] rounded-md  border-gray-400"
                />
              </div>
              </div>
              :(<><div className="flex gap-2  px-2">
                <div className="mb-4  ">
                  <label className="text-md font-bold text-gray-700  ">
                    Brand Name
                  </label>
                  <input
                    value={modelData.brandName}
                    onChange={e => {
                      setModelData({ ...modelData, brandName: e.target.value });
                    }}
                    className="w-full font-bold p-4 mt-4 border-[1px] rounded-md  border-gray-400"
                  />
                </div>
                <div className="mb-4">
                  <label className=" text-md font-bold text-gray-700 mb-6">
                    Product Name
                  </label>
                  <input
                    value={modelData.productName}
                    onChange={e => {
                      setModelData({
                        ...modelData,
                        productName: e.target.value,
                      });
                    }}
                    className="w-full  font-bold mt-4 p-4 border-[1px] rounded-md  border-gray-400"
                  />
                </div>
              </div>

              <div className="mb-4">
                <label className="text-md font-bold text-gray-700 mb-1">
                  Add to your model description for better training :
                </label>
                <textarea
                  value={modelData.description}
                  onChange={e => {
                    setModelData({ ...modelData, description: e.target.value });
                  }}
                  className="w-full mt-4 p-4 h-32 border-[1px] rounded-md  border-gray-400"
                />
              </div></>)
                }
              
                {/* for the product  */}
              
            </div>
            <div className="flex w-full  justify-center border-t-2">
              <div
                className="px-12 bg-black text-white font-bold p-2 m-2 text-center rounded-md cursor-pointer"
                onClick={() => {
                  submitModalDescription();
                }}
              >
                {`Proceed >`}
              </div>
              .
            </div>
          </div>
        )}
      </div>
      {!showProductDes && showCropper && (
        <div className="flex justify-center gap-2  border-[1px] border-t-gray-200 p-2">
          <div className="flex cursor-pointer ">
            <label
              htmlFor="file-upload"
              className=" border-gray-400 border-[1px] cursor-pointer  py-2 px-4 rounded-lg"
            >
              <div className="flex w-full h-full gap-2 ">
                <div className="h-full aspect-1">
                  <ImagePlus className="w-full h-full" />
                </div>
                <div>
                  <div className="flex w-full  ">Add more photos</div>
                </div>
              </div>
            </label>
            <input
              id="file-upload"
              type="file"
              ref={fileInputRef}
              multiple
              accept="image/png, image/jpeg"
              className="hidden"
              onChange={handleFileChange}
            ></input>
          </div>
          <button
            onClick={handleFinishCropping}
            className="bg-black text-white font-sans font-[600]   py-2 px-16 rounded-lg"
          >
            {uploadLoader ? 'Proceed' : `Proceed >`}
          </button>
        </div>
      )}
    </div>
  );
};

export default ModelImageUploadView;
