// src/components/ImageActions.jsx
import React from 'react';
import { Heart } from 'lucide-react';
import { toast } from 'react-toastify';
import { ReactComponent as Upscale } from '../../assets/icons/upscale.svg';
import { ReactComponent as UpscaleDone } from '../../assets/icons/upscale_done.svg';
import IconButton from '../IconButton';
import { useUpscale, useSaveToGallery } from '../../API';

const ImageActions = ({
  generationId,
  image,
  isSaved,
}) => {
  const upscaleMutation = useUpscale();
  const saveToGalleryMutation = useSaveToGallery();

  const isImageSaved = isSaved || image.saved_to_gallery;
  const isUpscaleRequested = image.upscale_status === 'REQUESTED';
  const isUpscaled = image.upscale_status === 'UPSCALED';

  let upscaleIcon,
    upscaleTitle,
    upscaleIconColor,
    isUpscaleDisabled,
    showUpscaleBorder;

  if (isUpscaleRequested) {
    upscaleIcon = UpscaleDone;
    upscaleTitle = 'Upscale in progress';
    upscaleIconColor = '#6bcc63';
    isUpscaleDisabled = true;
    showUpscaleBorder = false;
  } else if (isUpscaled) {
    upscaleIcon = UpscaleDone;
    upscaleTitle = 'View AI Upscale';
    upscaleIconColor = '#6bcc63';
    isUpscaleDisabled = true;
    showUpscaleBorder = false;
  } else {
    upscaleIcon = Upscale;
    upscaleTitle = 'AI Upscale';
    upscaleIconColor = 'white';
    isUpscaleDisabled = false;
    showUpscaleBorder = true;
  }

  const handleUpscale = () => {
    upscaleMutation.mutate(
      { generationId, imageId: image.image_id },
      {
        onSuccess: () => {
          toast.success('Upscale requested, please check after 5 mins', {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        },
        onError: () => {
          toast.error('Failed to request upscale. Please try again.', {
            position: 'bottom-center',
          });
        },
      }
    );
  };

  const handleSaveToGallery = () => {
    saveToGalleryMutation.mutate(
      { generationId, imageId: image.image_id },
      {
        onSuccess: () => {
          toast.success('Image saved to gallery successfully!', {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        },
        onError: () => {
          toast.error('Failed to save image to gallery', {
            position: 'bottom-center',
          });
        },
      }
    );
  };

  return (
    <div className="flex h-full gap-2">
      <IconButton
        icon={upscaleIcon}
        onClick={handleUpscale}
        title={upscaleTitle}
        color={upscaleIconColor}
        showBorder={showUpscaleBorder}
        disabled={isUpscaleDisabled}
      />
      <IconButton
        icon={Heart}
        onClick={handleSaveToGallery}
        title={isImageSaved ? 'Added to Gallery' : 'Save to Gallery'}
        disabled={isImageSaved}
        color={isImageSaved ? 'red' : 'white'}
        fill={isImageSaved ? 'red' : 'none'}
        showBorder={!isImageSaved}
      />
    </div>
  );
};

export default ImageActions;