import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '@clerk/clerk-react';
import { useParams,useNavigate } from 'react-router-dom';
import Loader from '../assets/icons/loader.svg'
const SubHeader = ({ activeTab, setActiveTab }) => {
  const tabStyle = tab => `
    px-4 py-2 mx-2 rounded-md transition-colors duration-200
    ${
      activeTab === tab
        ? 'text-black font-semibold'
        : 'text-gray-500 hover:text-gray-700'
    }
  `;

  return (
    <div className="flex justify-between items-center py-2 px-6 border-b border-gray-200">
      <div className="flex">
        {/* Placeholder for logo or other left-aligned content */}
      </div>

      <div className="flex justify-center flex-grow p-2">
        Have a look at your Captions
      </div>
    </div>
  );
};

const ImageCaptionView = () => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const navigate = useNavigate();
  const { model_id } = useParams();
  const [activeTab, setActiveTab] = useState('your_models');
  const [loading, setLoading] = useState(true);
  const [loadingCaptions,setLoadingCaptions]=useState(true);
  const [loadModelTraining,setModelTraining]=useState(false);

  const { getToken } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setLoadingCaptions(true);
      try {
        const token = await getToken({template: 'token'});
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/fetch-training-data?model_id=${model_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const images = response.data.training_data;
        console.log(images,"images");
        setUploadedImages(images);
         images.map(async image => {
          const captionResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/create-image-caption?model_id=${model_id}&image_id=${image.image_id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // Update the specific image with its caption
    setUploadedImages((prevImages) =>
      prevImages.map((prevImage) =>
        prevImage.image_id === image.image_id
          ? { ...prevImage, caption: captionResponse.data.caption }
          : prevImage
      )
    );
          // return {
          //   ...image,
          //   caption: captionResponse.data.caption,
          // };
        });

        // const imagesWithCaptions = await Promise.all(imagePromises);

        // setUploadedImages(imagesWithCaptions);
   setLoadingCaptions(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoadingCaptions(false);
        setLoading(false);
      }
    };

    fetchData();
  }, [model_id, getToken]);

  const handleCaptionChange = (index, newCaption) => {
    setUploadedImages(prevImages =>
      prevImages.map((img, i) =>
        i === index ? { ...img, caption: newCaption } : img
      )
    );
  };

  const handleSubmit = async () => {
setModelTraining(true)
    try {
      const token = await getToken({template: 'token'});
      setLoading(true);
      const formData = new FormData();

      // Append model_id to FormData
      formData.append('model_id', model_id);
      //  setLoading(false)
      // Prepare captions data
      let captionsData = uploadedImages.map(image => ({
        image_id: image.image_id,
        caption: image.caption,
      }));

      // Append captions as a string
      formData.append('captions', JSON.stringify(captionsData));

      // console.log(formData);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/submit-image-caption`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await axios.post(
        `${process.env.REACT_APP_API_URL}/trigger-training?model_id=${model_id}`,{},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      navigate(`/models`);

    } catch (error) {
      setModelTraining(false);
      setLoading(false);
      console.error('Error updating captions:', error);
    }
  };

  if (loading) {
    return <div className="w-full text-xl font-bold flex flex-col h-full justify-center items-center"> 
    {loadModelTraining && <div>starting model training ... </div>}
    {loadingCaptions &&<div>Creating Descriptions...</div>}
    <img className="h-20" src={Loader} alt="loader"/>
    </div>;
  }

  return (
    <div className="w-full mx-auto flex flex-col h-full overflow-hidden">
      <SubHeader activeTab={activeTab} setActiveTab={setActiveTab} />

      <div className="grid grid-cols-2  grow overflow-y-scroll md:grid-cols-3 lg:grid-cols-4  gap-4 py-8 px-10">
        {uploadedImages.map((image, index) => (
          <div key={index} className="bg-white w-full  flex flex-col rounded-lg ">
            <div className="w-full flex aspect-1 mb-4 rounded-lg bg-gray-100 ">

            <img
              src={image.image_url}
              alt={`Uploaded ${index}`}
              className="w-full object-contain rounded-lg"
            />
            </div>
            {/* <div className="grow"></div> */}
            {/* <p className="text-sm text-gray-600 mb-2">Description:</p> */}
            {
            <textarea
            value={image?.caption ? image?.caption :"Loading Description...." }
            onChange={e => handleCaptionChange(index, e.target.value)}
            className="w-full p-2 min-h-10 border rounded-md resize-vertical"
            rows="3"
          />
            }
            
          </div>
        ))}
      </div>

      {uploadedImages.length > 0 && (
        <div className="flex justify-center border-t-[1px] py-3">
          <button
            onClick={handleSubmit}
            className="bg-black font-bold text-white py-2 px-12 rounded-lg hover:bg-gray-800 transition-colors duration-200"
          >
           {`Proceed >`}
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageCaptionView;
