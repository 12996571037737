import React, { useState,useEffect,useCallback } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '@clerk/clerk-react';
import LazyImage from '../../components/LazyImage';
import ImageActions from '../../components/ImageActions';
import Loader from '../../assets/icons/loader.svg'
// import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';


const ImageGrid = ({ images, setSelectedImage ,page,setPage,fetchNextPage,hasNextPage, isFetchingNextPage}) => {

  const [savedImages, setSavedImages] = useState({});
  const [upscaleRequested, setUpscaleRequested] = useState({});
  const { getToken } = useAuth();
  const [hoveredImageId, setHoveredImageId] = useState(null);

  const lastImageRef = useCallback(node => {
    if (node !== null) {
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
          }
        },
        { threshold: 0.1 }
      );
      observer.observe(node);
      return () => observer.disconnect();
    }
  }, [hasNextPage, fetchNextPage]);
  const handleUpscale = async (image) => {
    try {
      const token = await getToken({ template: 'token' });
      const response = await fetch(`${process.env.REACT_APP_API_URL}/upscale`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          generation_id: image.generation_id,
          image_id: image.image_id,
        }),
      });

      if (response.ok) {
        toast.success("Upscale requested, please check after 5 mins", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setUpscaleRequested(prev => ({ ...prev, [image.image_id]: true }));
      } else {
        toast.error("Failed to request upscale. Please try again.", {
          position: "bottom-center",
        });
      }
    } catch (error) {
      console.error('Error requesting upscale:', error);
      toast.error("Failed to request upscale. Please try again.", {
        position: "bottom-center",
      });
    }
  };

  const handleSaveToGallery = async (image) => {
    try {
      const token = await getToken({ template: 'token' });
      const response = await fetch(`${process.env.REACT_APP_API_URL}/save-to-gallery`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          generation_id: image.generation_id,
          image_id: image.image_id,
        }),
      });

      if (response.ok) {
        toast.success("Image saved to gallery successfully!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setSavedImages(prev => ({ ...prev, [image.image_id]: true }));
      } else {
        toast.error("Failed to save image to gallery", {
          position: "bottom-center",
        });
        console.error('Failed to save image to gallery');
      }
    } catch (error) {
      console.error('Error saving image to gallery:', error);
      toast.error("Failed to save image to gallery", {
        position: "bottom-center",
      });
    }
  };
  const columns = [[], [], [], []];
  images.forEach((image, index) => {
    columns[index % 4].push(image);
  }); 

  
  return (
    <div className="px-4 py-8 w-full">
      <div className="flex flex-row gap-4 w-full">
        {columns.map((column, columnIndex) => (
          <div key={columnIndex} className="flex-1 flex flex-col gap-4">
            {column.map((image,index) => {
                const [aspectWidth, aspectHeight] = image.aspect_ratio.split(':').map(Number);
                const isLastImage = columnIndex === 3 && index === column.length - 1;
  
              return (
                <div
                  key={image.image_id}
                  ref={isLastImage ? lastImageRef : null}
                  className="relative w-full"
                  style={{ aspectRatio: `${aspectWidth} / ${aspectHeight}` }}
                  onMouseEnter={() => setHoveredImageId(image.image_id)}
                  onMouseLeave={() => setHoveredImageId(null)}
                >
                  <LazyImage
                    src={image.image_url}
                    alt={`Generated image ${image.image_id}`}
                    className="w-full h-auto object-cover rounded-[20px] cursor-pointer"
                    onClick={() => {
                      setSelectedImage({
                        image: image,
                        prompt: image.prompt,
                        generationId: image.generation_id,
                      });
                    }}
                  />
                  {hoveredImageId === image.image_id && (
                    <div className="absolute bottom-0 right-0 flex w-full justify-end p-2">
                      <ImageActions
                        image={image}
                        onUpscale={handleUpscale}
                        onSaveToGallery={handleSaveToGallery}
                        isSaved={savedImages[image.image_id]}
                        isUpscaleSubmitted={upscaleRequested[image.image_id]}
                        showDownload={true}
                        isUpscaleTab={false}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      {isFetchingNextPage && (
  <div className="w-full text-xl font-bold flex h-20 justify-center items-center mt-2 mb-10">
    <img className="h-20" src={Loader} alt="loader" />
  </div>
)}
    </div>
  );
  
};

export default ImageGrid;