import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const PaymentFailureView = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const amount = searchParams.get('amount');
  const type = searchParams.get('type');

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="p-8 bg-white rounded-lg shadow-md w-full max-w-md text-center">
        <h1 className="text-2xl font-bold mb-4 text-red-600">Payment Failed</h1>
        <p className="mb-4">
          Your {type === 'oneTime' ? 'one-time payment' : 'subscription'} of INR {amount} could not be processed. Please try again.
        </p>
        <Link to="/payments" className="text-blue-500 hover:text-blue-700">Try Again</Link>
      </div>
    </div>
  );
};

export default PaymentFailureView;