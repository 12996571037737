import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, ChevronDown, ChevronUp, Loader } from 'lucide-react';
import './TrainNewModel.css';
import useCredits from '../hooks/useCredits';
import { useUser, useAuth } from '@clerk/clerk-react';

const TrainNewModel = () => {
    const [modelType, setModelType] = useState('Product');
    const [name, setName] = useState('');
    const [triggerWord, setTriggerWord] = useState('');
    const [rank, setRank] = useState(16);
    const [saveEvery, setSaveEvery] = useState(250);
    const [maxSaves, setMaxSaves] = useState(4);
    const [batchSize, setBatchSize] = useState(1);
    const [steps, setSteps] = useState(1000);
    const [zipFile, setZipFile] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const [fileError, setFileError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState('');
    const { credits, updateCredits } = useCredits();
    const { user } = useUser();
    const { getToken } = useAuth();

    const guidelines = [
        { title: 'Multiple Images 20 - 30:', description: 'Include front, side, and top views.' },
        { title: 'Good Lighting:', description: 'Use evenly lit images with no shadows.' },
        { title: 'High Resolution:', description: 'Upload clear, sharp images (1024x1024+).' },
        { title: 'Simple Background:', description: 'Use plain, neutral backgrounds.' },
        { title: 'Product Only:', description: 'No extra items or distractions.' },
        { title: 'Consistent Appearance:', description: 'Same color and model across images.' },
        { title: 'No Duplicates:', description: 'Use varied images, not identical ones.' },
    ];

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type === 'application/zip') {
            setZipFile(file);
            setFileError('');
        } else {
            setZipFile(null);
            setFileError('Please upload a valid zip file.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitError('');
        setFileError('');
        setSubmitSuccess('');

        if (!zipFile) {
            setFileError('Please upload a zip file containing your training data.');
            return;
        }

        if (credits.current < 14) {
            setSubmitError('Not enough credits to perform this operation. You need 14 credits.');
            return;
        }

        const formData = new FormData();
        formData.append('image_data', zipFile);
        formData.append('name', name);
        formData.append('trigger_word', triggerWord);
        formData.append('rank', rank);
        formData.append('save_every', saveEvery);
        formData.append('max_saves', maxSaves);
        formData.append('batch_size', batchSize);
        formData.append('steps', steps);
        formData.append('modelType', modelType.toLowerCase());

        setIsLoading(true);

        try {
            console.log("Sending request to API...");
            const token = await getToken({template: 'token'});
            const response = await fetch(`${process.env.REACT_APP_API_URL}/general-lora-training`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData,
            });

            console.log("API response status:", response.status);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to submit job. Please try again.');
            }

            const result = await response.json();
            console.log('Job submitted successfully:', result);
            if (result.credits !== undefined) {
                await updateCredits(result.credits - credits.current);
            }
            setSubmitSuccess('Job submitted successfully!');
        } catch (error) {
            console.error('Error submitting job:', error);
            setSubmitError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container mx-auto p-6">
            <Link to="/" className="text-blue-500 mb-4 inline-block">&larr; Back to Models</Link>
            <h2 className="text-2xl font-bold mb-6">Train new model</h2>
            <div className="flex flex-wrap -mx-3">
                <div className="w-full md:w-1/2 px-3">
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label htmlFor="modelType" className="block mb-1">Model Type</label>
                            <select
                                id="modelType"
                                value={modelType}
                                onChange={(e) => setModelType(e.target.value)}
                                className="w-full p-2 border rounded"
                            >
                                <option>Product</option>
                                <option>Person</option>
                                <option>Style</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="name" className="block mb-1">Model name</label>
                            <input
                                id="name"
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                className="w-full p-2 border rounded"
                            />
                        </div>
                        <div>
                            <label htmlFor="triggerWord" className="block mb-1">Trigger word (optional)</label>
                            <input
                                id="triggerWord"
                                type="text"
                                value={triggerWord}
                                onChange={(e) => setTriggerWord(e.target.value)}
                                className="w-full p-2 border rounded"
                            />
                        </div>
                        <div>
                            <label htmlFor="steps" className="block mb-1">Steps</label>
                            <input
                                id="steps"
                                type="number"
                                value={steps}
                                onChange={(e) => setSteps(parseInt(e.target.value))}
                                required
                                className="w-full p-2 border rounded"
                            />
                        </div>
                        <div>
                            <label htmlFor="images" className="block mb-1">Upload images (ZIP file containing 20-30 images recommended)</label>
                            <input
                                id="images"
                                type="file"
                                accept=".zip"
                                onChange={handleFileChange}
                                className="w-full p-2 border rounded"
                            />
                            {fileError && <p className="text-red-500 text-sm mt-1">{fileError}</p>}
                        </div>
                        <div>
                            <button
                                type="button"
                                onClick={() => setShowMore(!showMore)}
                                className="text-blue-500 flex items-center"
                            >
                                {showMore ? 'Hide' : 'Show'} additional settings
                                {showMore ? <ChevronUp size={16} className="ml-1" /> : <ChevronDown size={16} className="ml-1" />}
                            </button>
                        </div>
                        {showMore && (
                            <div className="space-y-4 p-4 bg-gray-100 rounded">
                                <div>
                                    <label htmlFor="rank" className="block mb-1">Rank</label>
                                    <input
                                        id="rank"
                                        type="number"
                                        value={rank}
                                        onChange={(e) => setRank(parseInt(e.target.value))}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="saveEvery" className="block mb-1">Save every (optional)</label>
                                    <input
                                        id="saveEvery"
                                        type="number"
                                        value={saveEvery}
                                        onChange={(e) => setSaveEvery(parseInt(e.target.value))}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="maxSaves" className="block mb-1">Max saves (optional)</label>
                                    <input
                                        id="maxSaves"
                                        type="number"
                                        value={maxSaves}
                                        onChange={(e) => setMaxSaves(parseInt(e.target.value))}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="batchSize" className="block mb-1">Batch size</label>
                                    <input
                                        id="batchSize"
                                        type="number"
                                        value={batchSize}
                                        onChange={(e) => setBatchSize(parseInt(e.target.value))}
                                        required
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                            </div>
                        )}
                        {submitSuccess && <p className="text-green-500">{submitSuccess}</p>}
                        {submitError && <p className="text-red-500">{submitError}</p>}
                        <button
                            type="submit"
                            className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800 transition duration-200 flex items-center justify-center w-full"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <>
                                    <Loader className="animate-spin mr-2" size={20} />
                                    Processing...
                                </>
                            ) : (
                                <>
                                    Train now
                                    <ArrowRight className="ml-2" size={20} />
                                </>
                            )}
                        </button>
                    </form>
                </div>
                <div className="w-full md:w-1/2 px-3 mt-6 md:mt-0">
                    <div className="bg-gray-100 p-4 rounded">
                        <h3 className="text-xl font-semibold mb-4">Guidelines</h3>
                        <ul className="space-y-2">
                            {guidelines.map((guideline, index) => (
                                <li key={index}>
                                    <strong>{guideline.title}</strong> {guideline.description}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrainNewModel;