import React, { useState, useEffect, useCallback } from 'react';
import { useUser, useAuth } from '@clerk/clerk-react';
import './ModelSelectionPopup.css';
import Loader from '../assets/icons/loader.svg';

const SubHeader = ({ activeTab, setActiveTab }) => {
    const tabStyle = tab => `
      px-2 py-2 mx-1 rounded-md transition-colors duration-200 whitespace-nowrap
      ${activeTab === tab
            ? 'text-black font-semibold'
            : 'text-gray-500 hover:text-gray-700'
        }
    `;

    return (
        <div className="model-popup-header p-3 border-b-[1px] border-gray-200">
            <div className="flex justify-center items-center">
                <div className="flex justify-center flex-nowrap text-[16px] space-x-2">
                    <button
                        className={tabStyle('your_models')}
                        onClick={() => setActiveTab('your_models')}
                    >
                        Your Models
                    </button>
                    <button
                        className={tabStyle('foosh_models')}
                        onClick={() => setActiveTab('foosh_models')}
                    >
                        Foosh Models
                    </button>
                </div>
            </div>
        </div>
    );
};

const ModelSelectionPopup = ({ onSelectModel }) => {
    const [activeTab, setActiveTab] = useState('your_models');
    const [userModels, setUserModels] = useState([]);
    const [fooshModels, setFooshModels] = useState([]);
    const [loadingUserModels, setLoadingUserModels] = useState(true);
    const [loadingFooshModels, setLoadingFooshModels] = useState(true);

    const { user } = useUser();
    const { getToken } = useAuth();

    const fetchModels = useCallback(async (isUserModels) => {
        if (!user) return;

        if (isUserModels) {
            setLoadingUserModels(true);
        } else {
            setLoadingFooshModels(true);
        }

        try {
            const token = await getToken({ template: 'token' });
            const endpoint = isUserModels ? 'models' : 'models/public';
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/${endpoint}?type=all`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            const models = data.loras || [];
            const filteredModels = models.filter(model => model.status === 'SUCCESS');

            console.log(models);

            if (isUserModels) {
                setUserModels(filteredModels);
                setLoadingUserModels(false);
            } else {
                setFooshModels(filteredModels);
                setLoadingFooshModels(false);
            }
        } catch (error) {
            console.error(`Error fetching ${isUserModels ? 'user' : 'foosh'} models:`, error);
        }
    }, [user]);

    useEffect(() => {
        if (user && userModels.length === 0) {
            fetchModels(true);
        }
    }, [user, fetchModels, userModels.length]);

    useEffect(() => {
        if (activeTab === 'foosh_models' && fooshModels.length === 0) {
            fetchModels(false);
        }
    }, [activeTab, fetchModels, fooshModels.length]);

    const displayedModels = activeTab === 'your_models' ? userModels : fooshModels;
    const showLoader = activeTab === 'your_models' ? loadingUserModels : loadingFooshModels;

    return (
        <div className="model-popup-overlay">
            <div className="model-popup-content border-[1px] border-gray-300">
                <SubHeader activeTab={activeTab} setActiveTab={setActiveTab} />
                <div className="model-list">
                    {showLoader ? <div className="w-full text-xl font-bold flex h-full justify-center items-center">
                        <img className="h-20" src={Loader} alt="loader" />
                    </div> :
                        displayedModels.map(model => (
                            <div key={model.id} className="model-item px-2 cursor-pointer text-start hover:bg-[#F1F1F1] py-1" onClick={() => onSelectModel(model)}>
                                <img src={model.thumbnailUrl} alt={model.name} className="model-thumbnail w-[42px] aspect-1 rounded-lg" />
                                <span className="model-name text-[14px]">{model.name}</span>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default ModelSelectionPopup;