import React, { useEffect, useMemo } from 'react';
import { SignIn, useUser } from '@clerk/clerk-react';
import { BackgroundImages } from '../constants/BackgroundImages';


const SignInView = () => {
  const { user, isLoaded: isUserLoaded } = useUser();

  const randomBackground = useMemo(() => {
    const backgrounds = Object.values(BackgroundImages);
    return backgrounds[Math.floor(Math.random() * backgrounds.length)];
  }, []);

  useEffect(() => {
    if (isUserLoaded) {
      console.log('User loaded:', user);
      if (user) {
        console.log('Active user ID set:', user.id);
      } else {
        console.log('No user found.');
      }
    } else {
      console.log('User not loaded yet.');
    }
  }, [user, isUserLoaded]);

  return (
    <div 
      className="flex flex-col justify-between min-h-screen w-full bg-no-repeat bg-center bg-cover bg-fixed relative" 
      style={{ backgroundImage: `url('${randomBackground.image}')` }}
    >
      {/* Black overlay with 60% opacity */}
      <div className="absolute inset-0 bg-black opacity-60"></div>
      
      {/* Existing content */}
      <div className="flex items-center justify-center flex-grow relative z-10">
        <SignIn path="/sign-in" routing="path" signUpUrl="/sign-up" />
      </div>
      <div className="text-white text-sm p-4 absolute bottom-0 left-0 font-extralight z-10">
        <span className="opacity-50">Imagined on Foosh by</span> <span className="font-semibold">{randomBackground.name}</span> <span className="opacity-75">for</span> <span className="font-semibold">{randomBackground.brand}</span>
      </div>
    </div>
  );
};

export default SignInView;