import React, { useState, useEffect, useRef } from 'react';
import './InstructionPanel.css';
import { ArrowRight, X, Plus } from 'lucide-react';
import { useUser, useAuth } from '@clerk/clerk-react';
import { toast } from 'react-toastify';
import select_model_gif from '../assets/icons/select_model.gif';
import ModelSelectionPopup from './ModelSelectionPopup';

const AspectRatioOption = ({ value, label, selected }) => (
    <div className={`aspect-ratio-option ${selected ? 'selected' : ''}`}>
        <div className={`aspect-ratio-box ${value.replace(':', '-')}`}></div>
        <span>{label}</span>
    </div>
);

const InstructionPanel = ({ onGenerateImage, onGenerationStart, onGenerationEnd, setScrollManger, scrollDetect,
    setScrollDetect, setReferenceImageHandler }) => {

    const [isModelPopupOpen, setIsModelPopupOpen] = useState(false);
    const [selectedModel, setSelectedModel] = useState(
        localStorage.getItem('fooshModelSelected')
            ? JSON.parse(localStorage.getItem('fooshModelSelected'))
            : null
    );
    const [aspectRatio, setAspectRatio] = useState(() => {
        return localStorage.getItem('aspectRatioFoosh') || '1:1';
    });
    const [promptText, setPromptText] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const { user } = useUser();
    const { getToken } = useAuth();
    const textareaRef = useRef(null);
    const [referenceImage, setReferenceImage] = useState(null);
    const fileInputRef = useRef(null);
    const [isGeneratingCaption, setIsGeneratingCaption] = useState(false);
    const [captionText, setCaptionText] = useState('');

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
        setReferenceImageHandler(true)
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                setReferenceImage(e.target.result);
                await generateCaption(file);
            };
            reader.readAsDataURL(file);
        }
    };

    const generateCaption = async (file) => {
        setIsGeneratingCaption(true);
        setCaptionText('Generating caption...');

        const formData = new FormData();
        formData.append('image', file);

        try {
            const token = await getToken({ template: 'token' });
            const response = await fetch(`${process.env.REACT_APP_API_URL}/generate-ref-image-caption?model_id=${selectedModel.id}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setCaptionText(data.caption);
            setPromptText(data.caption);
            setIsExpanded(true);
        } catch (error) {
            console.error('Error generating caption:', error);
            toast.error('Failed to generate caption');
            setCaptionText('');
        } finally {
            setIsGeneratingCaption(false);
        }
    };

    const removeReferenceImage = () => {
        setReferenceImage(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleModelSelection = (model) => {
        setSelectedModel(model);
        localStorage.setItem('fooshModelSelected', JSON.stringify(model)); // Store selected model in local storage
        setIsModelPopupOpen(false);
    };

    const onRemoveModel = () => {
        setSelectedModel(null);
        localStorage.removeItem('fooshModelSelected'); // Remove the selected model from local storage
        removeReferenceImage();
    };

    useEffect(() => {
        if (isExpanded) {
            adjustTextareaHeight();
        }
    }, [promptText, isExpanded]);

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };

    const handleFocus = () => {
        setIsExpanded(true);
        setScrollManger(true);
        adjustTextareaHeight();
    };

    const handleBlur = () => {
        setIsExpanded(false);
        setScrollManger(false);
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = ''; // Reset to default (single line) height
        }
    };

    useEffect(() => {
        if (scrollDetect) {
            // Remove focus from textarea when scrolling starts
            if (document.activeElement === textareaRef.current) {
                textareaRef.current.blur();
            }
            handleBlur();
            setScrollDetect(false);
        }
    }, [scrollDetect]);

    const handlePromptChange = (e) => {
        setPromptText(e.target.value);
    };

    const handleGenerate = async () => {
        if (promptText.trim() === '' || !selectedModel) {
            toast.error('Please enter a prompt and select a model.');
            return;
        }

        if (!user) {
            toast.error('You must be logged in to generate images.');
            return;
        }

        setIsGenerating(true);
        onGenerationStart({promptText, aspectRatio});

        const payload = {
            prompt: promptText,
            models: [selectedModel.id],
            aspectRatio,
        };

        try {
            const token = await getToken({ template: 'token' });
            const response = await fetch(`${process.env.REACT_APP_API_URL}/generate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            while (true) {
                const { value, done } = await reader.read();
                const chunk = decoder.decode(value, { stream: true });

                try {
                    const jsonData = JSON.parse(chunk);
                    onGenerateImage(jsonData);
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                    console.error("Problematic chunk:", chunk);
                }
                if (done) break;
            }
            toast.success('Images generated successfully!');
        } catch (error) {
            console.error('Error during generation:', error);
            toast.error(`Generation failed: ${error.message}`);
        } finally {
            setIsGenerating(false);
            onGenerationEnd();
        }
    };

    return (
        <div className={`instruction-panel-container ${isExpanded ? 'expanded' : ''} `}>
            <div className="instruction-panel2 bg-white">
                <div className="instruction-panel-top-row ">
                    {selectedModel ? (
                        <>
                            <div className="selected-model-tag">
                                <img src={selectedModel.thumbnailUrl} alt={selectedModel.name} className="selected-model-thumbnail" />
                                <span>{selectedModel.name}</span>
                                <X size={12} onClick={() => onRemoveModel(selectedModel)} />
                            </div>
                            {selectedModel.type === "product" && (
                                <div
                                    className={`add-reference-image flex justify-center items-center gap-2 p-[0.45rem] m-[1.8px] rounded-md relative z-10 cursor-pointer`}
                                    onClick={handleUploadClick}
                                >
                                    <Plus size={16} />
                                    <div>Upload reference image</div>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                    />
                                    {referenceImage && (
                                        <div className="absolute w-16 aspect-3/4 rounded-lg  origin-bottom-left shadow-xl shadow-black bottom-[5rem] left-20">
                                            <img
                                                src={referenceImage} alt="Reference"
                                                className={`absolute  w-full aspect-3/4 object-cover rounded border-[1px] border-black z-20  -rotate-12`}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}

                        </>
                    ) : (
                        <div className="relative">
                            <div className="relative flex mr-2 rounded-lg ani-block" onClick={() => setIsModelPopupOpen(!isModelPopupOpen)}>
                                <div className={`select-model-button flex justify-center items-center gap-2 p-[0.45rem] m-[1.8px] ${isModelPopupOpen ? 'bg-gray-200' : 'bg-white'} rounded-md relative z-10`}>
                                    <img className="h-7 w-7 rounded-sm" src={select_model_gif} alt="gif" />
                                    <div>Select Model</div>
                                </div>
                            </div>
                            {isModelPopupOpen && (
                                <div className="absolute w-[350px] ">
                                    <ModelSelectionPopup
                                        onSelectModel={handleModelSelection}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    <div className="flex items-center gap-1 !px-4 aspect-ratio-select ">
                        <div className="w-5 h-5 rounded-sm bg-gray-200"></div>
                        <select
                            value={aspectRatio}
                            onChange={(e) => {
                                const newAspectRatio = e.target.value;
                                setAspectRatio(newAspectRatio);
                                localStorage.setItem('aspectRatioFoosh', newAspectRatio); // Save directly to localStorage
                            }}
                            className=" focus:outline-none "
                        >
                            <option value="4:3">
                                <AspectRatioOption value="4:3" label="4 : 3" selected={aspectRatio === '4:3'} />
                            </option>
                            <option value="3:4">
                                <AspectRatioOption value="3:4" label="3 : 4" selected={aspectRatio === '3:4'} />
                            </option>
                            <option value="1:1">
                                <AspectRatioOption value="1:1" label="1 : 1" selected={aspectRatio === '1:1'} />
                            </option>
                        </select>

                    </div>
                </div>
                <textarea
                    ref={textareaRef}
                    className={`prompt-textarea focus:outline-none ${isExpanded ? 'expanded' : ''}`}
                    placeholder={`Select a model and start creating right now :)`}
                    value={isGeneratingCaption ? captionText : promptText}
                    onChange={handlePromptChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    rows={1}
                />
            </div>

            <button
                className="generate-button"
                onClick={handleGenerate}
                disabled={isGenerating || !selectedModel || isGeneratingCaption}
            >
                <ArrowRight size={20} />
            </button>
        </div>
    );
};

export default InstructionPanel;