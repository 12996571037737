import React, { useState, } from 'react';

import SelectedImage from '../../components/SelectedImage';


import AllShotsView from './AllShotsView';
import SubHeader from './SubHeader';
import LikedUpscalesView from './LikedUpscalesView';


const GalleryView = () => {
  const [activeTab, setActiveTab] = useState('liked');
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <div className="w-full h-full  flex flex-col overflow-y-scroll">
      {selectedImage ? (
        <SelectedImage selectedImage={selectedImage} onClickCancel={() => setSelectedImage(null)} />
      ) : (
        <div className="flex flex-1 flex-col w-full">
          <SubHeader activeTab={activeTab} setActiveTab={setActiveTab} />
          {activeTab === 'all' ? (
            <AllShotsView setSelectedImage={setSelectedImage} />
          ) : (
            <LikedUpscalesView activeTab={activeTab} setSelectedImage={setSelectedImage} />
          )}
        </div>
      )}
    </div>
  );
};

export default GalleryView;