import React, { useState, useRef, useCallback } from 'react';
import { Heart } from 'lucide-react';
import { toast } from 'react-toastify';
import { ReactComponent as Upscale } from '../../assets/icons/upscale.svg';
import { ReactComponent as UpscaleDone } from '../../assets/icons/upscale_done.svg';
import { useUser, useAuth } from '@clerk/clerk-react';
import IconButton from '../IconButton';
import throttle from 'lodash.throttle';
import LazyImage from '../LazyImage';

const ImageActions = ({
  generationId,
  image,
  onUpscale,
  onSaveToGallery,
  isSaved,
}) => {
  const isImageSaved = isSaved || image.saved_to_gallery;
  const isUpscaleRequested = image.upscale_status === 'REQUESTED';
  const isUpscaled = image.upscale_status === 'UPSCALED';

  let upscaleIcon,
    upscaleTitle,
    upscaleIconColor,
    isUpscaleDisabled,
    showUpscaleBorder;

  if (isUpscaleRequested) {
    upscaleIcon = UpscaleDone;
    upscaleTitle = 'Upscale in progress';
    upscaleIconColor = '#6bcc63';
    isUpscaleDisabled = true;
    showUpscaleBorder = false;
  } else if (isUpscaled) {
    upscaleIcon = UpscaleDone;
    upscaleTitle = 'View AI Upscale';
    upscaleIconColor = '#6bcc63';
    isUpscaleDisabled = true;
    showUpscaleBorder = false;
  } else {
    upscaleIcon = Upscale;
    upscaleTitle = 'AI Upscale';
    upscaleIconColor = 'white';
    isUpscaleDisabled = false;
    showUpscaleBorder = true;
  }

  return (
    <div className="flex h-full gap-2">
      <IconButton
        icon={upscaleIcon}
        onClick={() => onUpscale(image, generationId)}
        title={upscaleTitle}
        color={upscaleIconColor}
        showBorder={showUpscaleBorder}
      />
      <IconButton
        icon={Heart}
        onClick={() => onSaveToGallery(image, generationId)}
        title={isImageSaved ? 'Added to Gallery' : 'Save to Gallery'}
        disabled={isImageSaved}
        color={isImageSaved ? 'red' : 'white'}
        fill={isImageSaved ? 'red' : 'none'}
        showBorder={!isImageSaved}
      />
    </div>
  );
};

const ShimmerEffect = () => (
  <div className="animate-pulse bg-gray-300 rounded-lg w-full h-64"></div>
);

const GenerationGroup = ({
  generationId,
  aspectRatio,
  images,
  prompt,
  setSelectedImage,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLastGroup,
  showPrompt
}) => {
  const [savedImages, setSavedImages] = useState({});
  const { user } = useUser();
  const { getToken } = useAuth();
  const [hoveredImageId, setHoveredImageId] = useState(null);
  const throttledFetchNextPage = useRef(
    throttle(() => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }, 2000) // Adjust the delay (in milliseconds) as needed
  ).current;
  const lastImageRef = useCallback(
    node => {
      if (node !== null) {
        const observer = new IntersectionObserver(
          entries => {
            if (entries[0].isIntersecting) {
              throttledFetchNextPage();
            }
          },
          { threshold: 0.1 }
        );
        observer.observe(node);
        return () => observer.disconnect();
      }
    },
    [throttledFetchNextPage]
  );
  const handleUpscale = async (image, generationId) => {
    if (!user) return;

    try {
      const token = await getToken({ template: 'token' });
      const response = await fetch(`${process.env.REACT_APP_API_URL}/upscale`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          generation_id: generationId,
          image_id: image.image_id,
        }),
      });

      if (response.ok) {
        toast.success('Upscale requested, please check after 5 mins', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        toast.error('Failed to request upscale. Please try again.', {
          position: 'bottom-center',
        });
      }
    } catch (error) {
      console.error('Error requesting upscale:', error);
      toast.error('Failed to request upscale. Please try again.', {
        position: 'bottom-center',
      });
    }
  };

  const handleSaveToGallery = async (image, generationId) => {
    if (!user) return;

    try {
      const token = await getToken({ template: 'token' });
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/save-to-gallery`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            generation_id: generationId,
            image_id: image.image_id,
          }),
        }
      );

      if (response.ok) {
        toast.success('Image saved to gallery successfully!', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setSavedImages(prev => ({ ...prev, [image.image_id]: true }));
      } else {
        toast.error('Failed to save image to gallery', {
          position: 'bottom-center',
        });
      }
    } catch (error) {
      console.error('Error saving image to gallery:', error);
      toast.error('Failed to save image to gallery', {
        position: 'bottom-center',
      });
    }
  };
  const [aspectWidth, aspectHeight] = aspectRatio.split(':').map(Number);
  return (
    <div key={generationId} className="mb-4 ">
      {showPrompt && <p className="text-sm mb-2">{prompt}</p>}
      <div className="grid grid-cols-4 gap-4">
        {images.map((image, imageIndex) => {

          return (
            <div
              key={`image-${imageIndex}`}
              ref={isLastGroup ? lastImageRef : null}
              className="relative flex  w-full rounded-xl bg-gradient-to-b from-white to-gray-200  "
              style={{ aspectRatio: `${aspectWidth} / ${aspectHeight}` }}
              onMouseEnter={() => setHoveredImageId(image?.image_id)}
              onMouseLeave={() => setHoveredImageId(null)}
            >
              {image ? (
                <>
                  <LazyImage
                    src={image.image_url}
                    alt={`Generated image ${image.image_id}`}
                    className="w-full h-auto object-cover rounded-[20px] cursor-pointer"
                    onClick={() => {
                      setSelectedImage({
                        image: image,
                        prompt: image.prompt,
                        generationId: image.generation_id,
                      });
                    }}
                  />
                  {/* <div className="w-full"  >
                  <img
                    src={image.image_url}
                    alt={`Generated  ${imageIndex + 1}`}
                    className="w-full object-cover rounded-xl cursor-pointer"
                    onClick={() =>
                      setSelectedImage({
                        image: image,
                        prompt: prompt,
                        generationId: generationId,
                      })
                    }
                  />
                </div> */}

                  {hoveredImageId === image.image_id && (
                    <div className="absolute bottom-2 right-2">
                      <ImageActions
                        generationId={generationId}
                        image={image}
                        onUpscale={handleUpscale}
                        onSaveToGallery={handleSaveToGallery}
                        isSaved={savedImages[image.image_id]}
                      />
                    </div>
                  )}
                </>
              ) : (
                <ShimmerEffect />
              )}
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default GenerationGroup;
