import React from 'react';

const DoAndDontSection = ({ dos, donts }) => {
  const renderItems = (items, icon) => (
    <div className="grid grid-cols-4 gap-2">
      {items.map((item, index) => (
        <div key={index} className="flex flex-col items-center">
          <div className="relative w-full max-w-xs">
            <div className="w-full aspect-1 rounded-xl bg-gray-100">
              <img
                className="w-full h-auto rounded"
                src={item.url}
                alt={item.description}
              />
            </div>
            <div className="absolute bottom-2 left-2 text-xl">
              {icon}
            </div>
          </div>
          <p className="w-full text-start mt-2 text-sm">
            {item.description}
          </p>
        </div>
      ))}
    </div>
  );

  return (
    <div className="grid grid-cols-1 w-1/2 gap-4 my-6 mx-auto text-start">
      <div className="space-y-4">
        {renderItems(dos, '✅️')}
      </div>
      <div className="space-y-4">
        {renderItems(donts, '⛔')}
      </div>
    </div>
  );
};

export default DoAndDontSection;