import React, { useEffect } from 'react';
import { SignUp, useUser } from '@clerk/clerk-react';

const SignUpView = () => {
  const { user, isLoaded: isUserLoaded } = useUser();

  useEffect(() => {
    if (isUserLoaded) {
      console.log('User loaded:', user);
      if (user) {
        console.log('Active user ID set:', user.id);
      } else {
        console.log('No user found.');
      }
    } else {
      console.log('User not loaded yet.');
    }
  }, [user, isUserLoaded]);

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <SignUp path="/sign-up" routing="path" signInUrl="/sign-in" />
    </div>
  );
};

export default SignUpView;