// ImageCropper.js
import React, { useState, useRef, useEffect } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "./CanvasPreview";
import { useDebounceEffect } from "../hooks/useDebounceEffect";
import "react-image-crop/dist/ReactCrop.css";
import { Plus } from "lucide-react";
import { Minus, Trash2 } from "lucide-react";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function ImageCropper({ src, onCropComplete, onDelete }) {
  const [imgSrc, setImgSrc] = useState();
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const containerRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect,setAspect] = useState(1);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setCrop(undefined); // Reset crop when a new image is loaded
    setImgSrc(src);
  }, [src]);

  // useEffect(() => {}, [imgRef.current]);

  function onImageLoad(e) {
    if (aspect) {
      setAspect(1)
      const { naturalWidth: width, naturalHeight: height } = e.target;
      const containerRefWidth = containerRef.current.offsetWidth;

      // Calculate the aspect ratio
      const aspectRatio = width / height;

      // Adjust dimensions to maintain aspect ratio
      let newWidth = width;
      let newHeight = height;

      if (newHeight > 485) {
        newHeight = 485;
        newWidth = newHeight * aspectRatio;
      }

      if (newWidth > containerRefWidth) {
        newWidth = containerRefWidth;
        newHeight = newWidth / aspectRatio;
      }

      setWidth(newWidth);
      setHeight(newHeight);
      const cropSize = Math.min(newWidth, newHeight);
      const crop = {
        unit: "px",
        width: cropSize,
        height: cropSize,
        x: (newWidth - cropSize) / 2,
        y: (newHeight - cropSize) / 2,
      };

      setCrop(crop);
      setCompletedCrop(crop);
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        // Generate the blob after the canvas has been drawn
        const previewCanvas = previewCanvasRef.current;

        previewCanvas.toBlob(
          (blob) => {
            if (blob && onCropComplete) {
              onCropComplete(blob);
            }
          },
          "image/png",
          1
        );
      } else if (imgRef.current) {
        // If no crop is done, use the original image
        const response = await fetch(src);
        const blob = await response.blob();
        if (onCropComplete) {
          onCropComplete(blob);
        }
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  return (
    <div
      className="flex justify-center w-full aspect-[3/4] items-center relative rounded-md"
      ref={containerRef}
    >
      <div className="absolute top-0 right-0 z-[1000] ">
        {/* Removed the file input since we're passing the src as a prop */}
        <div className="flex  w-full gap-1  p-2   ">
          <div
            onClick={() =>
              setScale((prevScale) => Math.max(0.1, prevScale - 0.1))
            }
            disabled={!imgSrc}
            className="h-6 w-6 bg-black bg-opacity-50 text-white text-xl flex justify-center items-center  rounded-sm  cursor-pointer"
          >
            <Minus/>
          </div>
          {/* <span>Scale: {scale.toFixed(1)}</span> */}
          <div
            onClick={() => setScale((prevScale) => prevScale + 0.1)}
            disabled={!imgSrc}
            className="   bg-black rounded-sm bg-opacity-50 text-white flex h-6 w-6 text-xl  justify-center items-center cursor-pointer"
          >
            <Plus />
          </div>
          <div className="grow"></div>
          <div
            onClick={onDelete}
            className="h-6 w-6 p-1 text-xl flex justify-center items-center  bg-black rounded-sm bg-opacity-50 text-white cursor-pointer"
          >
            <Trash2 />
          </div>
        </div>
      </div>
      {!!imgSrc && (
        <ReactCrop
          crop={crop}
          onChange={(newCrop) => setCrop(newCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
          className="h-max w-max custom-crop"
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{ transform: ` scale(${scale})  rotate(${rotate}deg)`, height, width }}
            onLoad={onImageLoad}
            // align self to the center
            className="object-contain"
          />
        </ReactCrop>
      )}
      {/* Canvas is hidden since we don't need to display it */}
      <canvas ref={previewCanvasRef} className="hidden" />
    </div>
  );
}
