import React, { useState, useEffect, useRef } from 'react';
import { useUser, useAuth } from '@clerk/clerk-react';
import InstructionPanel from './InstructionPanel';
import GeneratedImages from './GeneratedImages';
import SelectedImage from '../components/SelectedImage';
import GenerationGroup from '../components/GenerationGroup';
import './StudioView.css';

const StudioView = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [generatedGroups, setGeneratedGroups] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { user } = useUser();
  const { getToken } = useAuth();
  const [scrollManger, setScrollManger] = useState(false);
  const [newGenerationId, setNewGenerationId] = useState(null);
  const [newGenerationImages, setNewGenerationImages] = useState([]);
  const [newGenerationPrompt, setNewGenerationPrompt] = useState(null);
  const [newGenerationAspectRatio, setNewGenerationAspectRatio] = useState(null);
  const [scrollDetect, setScrollDetect] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const scrollContainerRef = useRef(null);
  const [referenceImageHandler, setReferenceImageHandler] = useState(false);
  useEffect(() => {
    if (user) {
      fetchGeneratedGroups(1);
    }
  }, [user]);

  const fetchGeneratedGroups = async pageNum => {
    if (!user) return;

    try {
      const token = await getToken({ template: 'token' });
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/generated-images?page=${pageNum}&limit=6&filter=user`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        const newGroups = data.images || [];
        setGeneratedGroups(prevGroups =>
          pageNum === 1 ? newGroups : [...prevGroups, ...newGroups]
        );
        setTotalPages(data.total_pages);
        setHasMore(newGroups.length === 6);
        setPage(pageNum);
      }
    } catch (error) {
      console.error('Error fetching generated groups:', error);
    }
  };

  const handleGenerationStart = ({ prompt, aspectRatio }) => {
    console.log('Creating new generated group with prompt as ', prompt);
    setNewGenerationId('generating');
    setNewGenerationImages(Array(4).fill(null));
    setNewGenerationPrompt(prompt);
    setNewGenerationAspectRatio(aspectRatio);

    setIsGenerating(true);
  };

  const handleImageGeneration = newImage => {
    // Update the generation id if it is not present
    if (newGenerationId === 'generating') {
      setNewGenerationId(newImage.generation_id);
    }
    setNewGenerationImages(prevImages => {
      const updatedImages = [...prevImages];
      const emptyIndex = updatedImages.findIndex(img => img === null);
      if (emptyIndex !== -1) {
        updatedImages[emptyIndex] = newImage;
      } else {
        console.warn('No empty slot found for new image');
      }

      if (emptyIndex === 3) {
        setIsGenerating(false);
        setGeneratedGroups(prevGroups => {
          return [
            {
              aspect_ratio: newGenerationAspectRatio,
              prompt: newImage.prompt,
              images: updatedImages,
              generation_id: newImage.generation_id,
            },
            ...prevGroups,
          ];
        });
      }

      return updatedImages;
    });
  };

  const handleGenerationEnd = () => {
    setIsGenerating(false);
  };

  const loadMore = () => {
    const nextPage = page + 1;
    if (nextPage <= totalPages) fetchGeneratedGroups(nextPage);
  };

  const handleScroll = () => {
    console.log(scrollContainerRef.current);
    if (scrollContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollContainerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        // 100px before the end
        loadMore();
      }
    }
    setScrollDetect(true);
  };

  return (
    <div className="studio-container  overflow-hidden flex flex-col w-full flex-1">
      {selectedImage ? (
        <SelectedImage
          selectedImage={selectedImage}
          onClickCancel={() => setSelectedImage(null)}
        />
      ) : (
        <>
          <div
            className={`instruction-panel ${referenceImageHandler ? '!pt-16 ' : ''
              } bg-[#F0F0F0] ${scrollManger ? 'fixed top-15 left-0 right-0 z-10' : ''
              }`}
          >
            <InstructionPanel
              onGenerateImage={handleImageGeneration}
              onGenerationStart={handleGenerationStart}
              onGenerationEnd={handleGenerationEnd}
              setScrollManger={setScrollManger}
              scrollDetect={scrollDetect}
              setScrollDetect={setScrollDetect}
              setReferenceImageHandler={setReferenceImageHandler}
            />
          </div>
          <div
            className={`generation-panel  flex-grow flex flex-col overflow-hidden ${scrollManger
              ? 'fixed top-[193.984375px] left-0 right-0 bottom-0'
              : ''
              }`}
          >
            <div
              ref={scrollContainerRef}
              className={`w-full flex-grow overflow-y-auto ${scrollManger ? 'h-full' : ''
                }`}
              onScroll={handleScroll}
            >
              <div className="p-4">
                {isGenerating && (
                  <GenerationGroup
                    showPrompt={true}
                    aspectRatio={newGenerationAspectRatio}
                    generationId={newGenerationId}
                    images={newGenerationImages}
                    prompt={newGenerationPrompt}
                    setSelectedImage={setSelectedImage}
                  />
                )}
                <GeneratedImages
                  showPrompt={true}
                  generatedGroups={generatedGroups}
                  loadMore={loadMore}
                  hasMore={hasMore}
                  setSelectedImage={setSelectedImage}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StudioView;
