import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SignInButton, SignOutButton, useAuth, useUser, useOrganizationList, useOrganization } from '@clerk/clerk-react';
import { ChevronDown, RefreshCw } from 'lucide-react'; // Import the refresh icon
import useCredits from '../hooks/useCredits';

const Header = () => {
    const location = useLocation();
    const { isSignedIn, isLoaded } = useAuth();
    const { user } = useUser();
    const { organizationList } = useOrganizationList();
    const { setActive, organization } = useOrganization();
    const [profileImageUrl, setProfileImageUrl] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { credits } = useCredits();

    useEffect(() => {
        if (isSignedIn && user) {
            if (user.profileImageUrl) {
                setProfileImageUrl(user.profileImageUrl);
            } else if (user.imageUrl) {
                setProfileImageUrl(user.imageUrl);
            } else {
                setProfileImageUrl(null);
            }
        }
    }, [isSignedIn, user]);

    // useEffect(() => {
    //     // Soft refresh credits every 60 seconds
    //     const intervalId = setInterval(() => {
    //         softRefresh();
    //     }, 60000);

    //     return () => clearInterval(intervalId);
    // }, [softRefresh]);

    useEffect(() => {
        if (organizationList && organizationList.length > 0) {
            const activeOrg = organizationList[0]; // Set the first organization as active
            setActive(activeOrg.id);
        }
    }, [organizationList, setActive]);

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    return (
        <header className="flex relative justify-center items-center py-4 px-6 border-b  bg-black text-white">
            <h1 className="text-2xl font-bold absolute left-0 px-6 ">FOOSH</h1>
            <nav className="">
                <ul className="flex space-x-6">
                    {[
                        { name: 'Models', path: '/models' },
                        { name: 'Studio', path: '/studio',path2:"/" },
                        { name: 'Gallery', path: '/gallery' },
                    ].map((item) => (
                        <li key={item.name}>
                            <Link
                                to={item.path}
                                className={`font-[400] ${
                                    location.pathname === item.path || location.pathname===item?.path2 ? 'text-white' : 'text-white opacity-30'
                                }`}
                            >
                                {item.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
            <div className="absolute right-0 px-6  flex min-w-12 items-center space-x-4 text-white font-[400]">
                {isLoaded && isSignedIn && (
                    <>
                     {credits.current!==null && <div className="ml-4 text-sm font-[400]">
                            {credits.current} credits left
                        </div>}
                        {/* <button
                            className="px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded"
                            // onClick={softRefresh}
                        >
                            <RefreshCw size={20} />
                        </button> */}
                    </>
                )}
                {isLoaded && (
                    isSignedIn ? (
                        <div className="relative">
                            <button
                                onClick={toggleDropdown}
                                className="flex items-center space-x-2 focus:outline-none"
                            >
                                {profileImageUrl ? (
                                    <img src={profileImageUrl} alt="Profile" className="w-8 h-8 rounded-full" />
                                ) : (
                                    <svg className="w-8 h-8 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
                                        <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                                    </svg>
                                )}
                                <span className="text-sm font-[400]">{user.fullName}</span>
                                <ChevronDown size={16} />
                            </button>
                            {isDropdownOpen && (
    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
        <Link 
            to="/payments" 
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 font-bold"
            onClick={() => setIsDropdownOpen(false)}
        >
            UPGRADE
        </Link>
        <SignOutButton>
            <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 font-bold">
                Sign Out
            </button>
        </SignOutButton>
    </div>
)}
                        </div>
                    ) : (
                        <SignInButton />
                    )
                )}
            </div>
        </header>
    );
};

export default Header;